import React from 'react'

import MyCalendar from "./MyCalendar"
import EventCategories from "./EventCategories"
import EventTimer from "./EventTimer";

export default function DashboardEvents({events, user, hosts, categories, firebaseData, products}) {


  const eventsWithAddedData = events.map((event) => {
    const result = {
      ...event,
      ...EventTimer(event),
    };
    // FIX - PRETTY UGLY
    if(firebaseData[event.frontmatter.uid] && firebaseData[event.frontmatter.uid].videoUrl) {
      result.videoUrl = {...firebaseData[event.frontmatter.uid].videoUrl};
    }
    if (!event.frontmatter.product.stripeID) return result;
    if(firebaseData[event.frontmatter.uid]) {
      result.productInventory = {
        product: firebaseData[event.frontmatter.uid].product,
        prices: firebaseData[event.frontmatter.uid].prices,
      };
    }
    result.stripeProduct = products.find(
      (product) => product.id === event.frontmatter.product.stripeID
      );
    return result;
  });

  return (
    <>
      <MyCalendar 
        events={eventsWithAddedData.filter((event) => user.events && -1 !== user.events.indexOf(event.frontmatter.uid))} 
        user={user} 
        hosts={hosts} 
        inventory={firebaseData} 
        products={products} 
      />
      <EventCategories 
        events={eventsWithAddedData.filter((item) => !user.events || -1 === user.events.indexOf(item.frontmatter.uid))} 
        categories={categories} 
        user={user} 
        hosts={hosts} 
        inventory={firebaseData} 
        products={products}
      />
    </>
  )
}
