// export const formatAmount = (amount, currency = "USD") => {
//   amount = zeroDecimalCurrency(amount, currency)
//     ? amount
//     : (amount / 100).toFixed(2);
//   return new Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency,
//   }).format(amount);
// };

// function zeroDecimalCurrency(amount, currency) {
//   console.log("zdc", amount);
//   console.log("zdc", currency);
//   let numberFormat = new Intl.NumberFormat(["en-US"], {
//     style: "currency",
//     currency: currency,
//     currencyDisplay: "symbol",
//   });
//   const parts = numberFormat.formatToParts(amount);
//   let zeroDecimalCurrency = true;
//   for (let part of parts) {
//     if (part.type === "decimal") {
//       zeroDecimalCurrency = false;
//     }
//   }
//   return zeroDecimalCurrency;
// }

export const formatAmount = (val) => {
  const dollars = val / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
