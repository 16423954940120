import React from "react";

const PersonCard = ({data}) => {

    // See if we have an image.
    let personImage = (
      <></>
    );

    if (data.childMdx.frontmatter.avatar != null) {
      if (data.childMdx.frontmatter.avatar.image != null) {
        personImage = (
          <img
            src={data.childMdx.frontmatter.avatar.image.childImageSharp.fluid.src}
            alt={data.childMdx.frontmatter.title}
          />
        );
      }
    }

    let personName = "";
    let personTitle = "";
    let personBio = "";

    if (data.childMdx.frontmatter.title != null)
        personName = data.childMdx.frontmatter.title;

    if (data.childMdx.frontmatter.jobTitle != null)
        personTitle = data.childMdx.frontmatter.jobTitle;

    if (data.childMdx.frontmatter.bio != null)
        personBio = data.childMdx.frontmatter.bio;

    return (
        <div>
            <div className="flex align-center marginTop-6">
                <div>
                  <div className="host-placeholder marginRight-4">
                      {personImage}
                  </div>
                </div>
                <div>
                    <div className="bold c-navy marginBottom-1">{personName}</div>
                    <div>{personTitle}</div>
                </div>
            </div>
            <div className="marginBottom-8 marginTop-4">{personBio}</div>
        </div>
    );
};

export default PersonCard;
