import React, {useState} from 'react'
import Button from "./Button";
import Modal from "./Modal";
import TabView from "./TabView";
import EventsList from "./EventsList";
import { useModal } from "react-modal-hook";
import useSnackBars from "../hooks/useSnackBars";
import UserInfo from "./UserInfo";
import { signOut } from "../utils/firebase-utils";

export default function MyCalendar({events, user, hosts}) {
  const { addAlert } = useSnackBars();
  const [activeTab, setActiveTab] = useState("Upcoming");
  const upcomingEvents = [];
  const pastEvents = [];
  const [showUserInfo, hideUserInfo] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideUserInfo} onExited={onExited}>
        <div className="modal--lg">
          <UserInfo 
            user={user}
            onCancel={hideUserInfo}
            onSuccess={() => {
              hideUserInfo();
              addAlert("Your profile has been updated.");
            }} 
          />
        </div>
      </Modal>
    ),
    [user]
  );
    
  if(events && events.length) {
    events.forEach((event) => {
      if (event.isEventLive || !event.hasEventEnded) {
        upcomingEvents.push(event);
      } else {
        pastEvents.push(event);
      }
    });
  
    if (!upcomingEvents.length && !pastEvents.length) return null;
  
    if (activeTab === "Upcoming" && !upcomingEvents.length) {
      setActiveTab("Past");
    }
  
    if (activeTab === "Past" && !pastEvents.length) {
      setActiveTab("Upcoming");
    }
  }


  return (
    <>
      <div className="padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween flex-align--center flex-wrap ">
        <div className="width--100 flex flex-justify--spaceBetween flex-align--start">
          <h1 className="headline1 event-category">My Courses</h1>
          <div>
            <Button
              className="button--secondary"
              onClick={() => {
                addAlert("👋 You've been signed out.");
                signOut();
              }}
            > Sign out
            </Button>
          </div>
        </div>
        <div className="width--100 flex space-between marginTop-6">
          <div className="marginRight-6">
            <div className="marginBottom-1">
              {user.firstName} {user.lastName}
            </div>
            <Button className="button--textonly" onClick={showUserInfo} >Edit Profile</Button>
          </div>
        </div>
      </div>
      {events.length > 0 ?
        <>
          <TabView
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className="padding-6 paddingTop-0 md_padding-10 lg_padding-16 lg_paddingTop-0 md_paddingTop-0 "
          >
            {upcomingEvents.length && (
              <div label="Upcoming">
                <EventsList
                  category={"My Courses"}
                  user={user}
                  items={upcomingEvents}
                  hosts={hosts}
                />
              </div>
            )}
            {pastEvents.length && (
              <div label="Past">
                <EventsList
                  category={'My Courses'}
                  user={user}
                  items={pastEvents}
                  hosts={hosts}
                />
              </div>
            )}
          </TabView>
          <div className="margin-6 marginTop-0 md_margin-10 md_marginTop-0 lg_margin-16 lg_marginTop-0 marginBottom-6">
            <a
              href="https://canlis.com/uploads/CCC%20Calendar.pdf"
              target="_blank"
              className="button button--secondary"
            >
              Print the full calendar
            </a>
          </div>
        </>
      :
      <div className="elevated padding-4 md_padding-7 margin-6 marginTop-0 md_margin-10 md_marginTop-0 lg_margin-16 lg_marginTop-0 lg_width--66">
        <h2 className="headline2 marginBottom-3">
          Class is in session.
        </h2>
        <p>
          Enroll in live courses, intramurals, or field trips. They'll
          appear right here. Hit the campus store to sport some school
          spirit or the cafeteria for some quality TV dinners.
        </p>
        <a
          href="https://canlis.com/uploads/CCC%20Calendar.pdf"
          target="_blank"
          className="button button--secondary marginTop-4"
        >
          Print the full calendar
        </a>
      </div>
      }
      <div className="divider"></div>
    </>
  )
}
