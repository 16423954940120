import React from 'react';

const Select = ({ className, items, selected, onChange }) => {
  return (
    <select
      className={`select padding-3 ${className ? className : ''}`}
      name="select"
      id="select"
      value={selected}
      onChange={onChange}
    >
      {items ? (
        items.map((item, i) => {
          if (!item.value) return undefined;
          return (
            <option key={item.value} id={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })
      ) : (
        <option value={"loading"}>Loading...</option>
      )}
    </select>
  );
};

export default Select;