import React, { useState } from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getErrorMessage } from "../utils/helpers";
import {
  addUserPaymentMethod,
  getUserData,
  createStripeIntent,
  getCurrentUserUID,
} from "../utils/firebase-utils";

import Button from "./Button";

const AddPaymentMethodForm = ({ onCancel, onSuccess }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };

  const handleOnCardSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const cardElement = elements.getElement(CardElement);
    const user = await getUserData();
    const uid = await getCurrentUserUID();
    await createStripeIntent(uid, user.customer_id);
    const updatedUser = await getUserData();

    const { setupIntent, error } = await stripe.confirmCardSetup(
      updatedUser.setup_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${user.fullName}`,
          },
        },
      }
    );
    if (error) {
      return handleError(error.code);
    }

    const paymentMethod = await addUserPaymentMethod(
      uid,
      setupIntent.payment_method
    );

    onSuccess(paymentMethod);
  };

  return (
    <div className="marginTop-4">
      <div className="border--bottom paddingBottom-2 marginBottom-4">
        <CardElement />
      </div>
      {hasError && <p className="c-red">{hasError}</p>}
      <div className="flex flex-justify--end">
        <Button
          type="button"
          className="button button--clean c-black marginRight-4"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <button
          disabled={isSubmitting}
          type="button"
          className={`button ${isSubmitting ? "button--loading" : ""}`}
          onClick={handleOnCardSubmit}
        >
          {isSubmitting ? <span>Adding...</span> : "Add Card"}
        </button>
      </div>
    </div>
  );
};

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const AddPaymentMethod = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <AddPaymentMethodForm {...props} />
    </Elements>
  );
};

export default AddPaymentMethod;
