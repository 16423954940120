import React, { useState } from "react";

const NumberInput = ({ className, onChange, defaultValue, min = 1, max }) => {
  const [value, setValue] = useState(defaultValue);

  const handleOnChange = (val) => {
    let result = parseInt(val);
    if (val < min) result = min;
    if (val > max) result = max;
    setValue(result);
    if (onChange) onChange(result);
  };

  const handleOnBlur = (val) => {
    let result = parseInt(val);
    if (max && val > max) result = max ? max : val;
    if (val < min) result = min;

    setValue(result);
    if (onChange) onChange(result);
  };

  return (
    <span className={`numberInput ${className ? className : ""}`}>
      <button disabled={min >= value} onClick={() => handleOnChange(value - 1)}>
        –
      </button>
      <input
        type="number"
        value={value}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={(e) => handleOnBlur(e.target.value)}
        min={min}
        max={max}
      />
      <button disabled={max <= value} onClick={() => handleOnChange(value + 1)}>
        +
      </button>
    </span>
  );
};

export default NumberInput;
