import React, { useState } from "react";
import TabView from "./TabView";
import EventsList from "./EventsList";
import Collapse from "./Collapse";
import Icon from "./Icon";

const EventsCategory = ({
  title,
  description,
  user,
  items,
  hosts,
  emptyState,
  category,
  isOpen,
  isCategory,
}) => {
  const upcomingEvents = [];
  const pastEvents = [];
  const [activeTab, setActiveTab] = useState("Upcoming");
    
  if(!items || !items.length) return null;

  items.forEach((event) => {
    if (event.isEventLive || !event.hasEventEnded) {
      upcomingEvents.push(event);
    } else {
      pastEvents.push(event);
    }
  });

  if (!upcomingEvents.length && !pastEvents.length) return null;

  if (activeTab === "Upcoming" && !upcomingEvents.length) {
    setActiveTab("Past");
  }

  if (activeTab === "Past" && !pastEvents.length) {
    setActiveTab("Upcoming");
  }

  return (
    <>
      <section>
        <Collapse
          isOpen={isOpen}
          buttonClasses={"width--100"}
          buttonContents={(isOpen) => (
            <div className="padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween flex-align--center flex-wrap ">
              <div className="width--100">
                <h1 className="headline1 event-category">{title}</h1>
                {description &&
                  <p className="bold c-navy marginTop-4 md_marginTop-8">{description}</p>
                }
              </div>
                  <div
                    className={`relative ${isOpen ? "rotateX-180" : ""}`}
                    style={{ transition: "transform 200ms ease" }}
                  >
                    <Icon
                      name="expandArrow"
                      className="expand-arrow absolute"
                    />
                  </div>

            </div>
          )}
        >
          {items.length || !emptyState ? (
            <>
            <TabView
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              className="padding-6 paddingTop-0 md_padding-10 lg_padding-16 lg_paddingTop-0 md_paddingTop-0 "
            >
              {upcomingEvents.length && (
                <div label="Upcoming">
                  <EventsList
                    category={category}
                    user={user}
                    items={upcomingEvents}
                    hosts={hosts}
                  />
                </div>
              )}
              {pastEvents.length && (
                <div label="Past">
                  <EventsList
                    category={category}
                    user={user}
                    items={pastEvents}
                    hosts={hosts}
                  />
                </div>
              )}
            </TabView>
            </>
          ) : (
            emptyState()
          )}
        </Collapse>
      </section>
      <div className="divider"></div>
    </>
  );
};

export default EventsCategory;
