import React from "react";

const Tab = ({ label, isActive, onClick, className }) => {
  return (
    <button
      disabled={isActive}
      className={`
        button--clean
        tab
        bold
        ${isActive ? "active" : ""}
        ${className ? className : ""}
      `}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Tab;
