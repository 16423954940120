import React from "react";

const LowerContent = ({isSignedIn}) => {
  return (
    <>
      <section className="block border--bottom padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween overflow--hidden relative ">
        <div className="cap"></div>
        <div className="width--100 lg_width--50">
          <h2 className="headline1 marginBottom-3">Campus Store</h2>
          <h3 className="headline2 marginBottom-3">
            Smells like school spirit.
          </h3>
          {isSignedIn ?
            <>
              <p className="marginBottom-4">
                Shop gear from Filson, Ebbets Field, Blackwing, and much more.
              </p>
              <a
                href="https://canlis.squarespace.com"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Shop now
              </a>
            </>
          :
            <p className="marginBottom-4">
            Enroll and shop gear from Filson, Ebbets Field, BlackWing, and much more.
            </p>
          }
        </div>
      </section>
      <div className="flex flex-wrap">
        <section className="block border--bottom padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween width--100 lg_width--50 flex-wrap">
          <div className="width--100">
            <h2 className="headline1 marginBottom-3">Canlis Kids</h2>
            <h3 className="headline2 marginBottom-3">
              Calling All Kids!
            </h3>
            <p className="marginBottom-4">
              Join Mark and Brian Canlis as they travel to the Woodland Park Zoo and Seattle Aquarium, and learn just how much wildlife has to teach us.
            </p>
            <div className="marginBottom-4 bold c-green">
              Canlis Kids airs on our YouTube channel each Tuesday at 4:30pm starting October 13th.
            </div>
            <a
              href="https://www.youtube.com/c/Canlis"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Subscribe now
            </a>
          </div>
        </section>
        <section className="block border--bottom padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween lg_width--50">
          <div className="width--100">
            <h2 className="headline1 marginBottom-3">Farestart</h2>
            <h3 className="headline2 marginBottom-3">
              This is all for a great cause.
            </h3>
            <p className="marginBottom-4">
              No really. After we pay to keep the lights on, all proceeds from Canlis Community College are donated to Seattle’s Farestart. With food insecurity on everyone’s hearts, we’re proud to support the life changing work they do.
            </p>
            <a
              href="https://www.farestart.org/"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Visit Farestart
            </a>
          </div>
        </section>
      </div>
      <section className="block border--bottom padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween">
        <div className="width--100">
          <h2 className="headline1 marginBottom-5">Partners</h2>
          <div className="flex flex-wrap partner-names">
            <div className="headline2">
              <span>Rainier Industries</span>
              <span>Glazer's Camera</span>
              <span>Woodland Park Zoo</span>
              <span>Seattle Aquarium</span>
              <span>Big Rock Productions</span>
              <span>Burke Museum</span>
              <span>The Bread Lab Collective</span>
              <span>Climate Pledge Arena</span>
              <span>Ebbets Field Flannels</span>
              <span>Filson</span>
              <span>Finnriver Farm and Cidery</span>
              <span>Kamonegi</span>
              <span>KEXP</span>
              <span>Nordstrom</span>
              <span>Northwest African American Museum</span>
              <span>Museum of History & Industry</span>
              <span>Musang</span>
              <span>Space Needle</span>
              <span>Pacific Northwest Ballet</span>
              <span>Pike Place Market</span>
              <span>Rudy's Barbershop</span>
              <span>Seattle Art Museum</span>
              <span>Seattle Kraken</span>
              <span>Taylor Shellfish</span>
              <span>Visit Seattle</span>
              <span>
                Wing Luke Museum of the Asian Pacific American Experience
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer className="padding-6 md_padding-10 lg_padding-16">
        <div className="text-feature">
          <div>
            Space Needle® (use of Space Needle approved by Space Needle LLC)
          </div>
        </div>
      </footer>
    </>
  );
};

export default LowerContent;
