import React from "react";
import moment from "moment";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import Button from "./Button";
import EventDetails from "./EventDetails";
import Icon from "./Icon";

const EventCard = ({ event, hosts, user }) => {
  let hasUserPurchasedKit = false;
  // let pickupDateDisplay = "";
  const eventHasProduct = !!event.frontmatter.product.stripeID;

  const {
    hasPurchasingClosed,
    hasEventEnded,
    isEventStartingSoon,
    isEventLive,
    timeUntilStartMins,
  } = event;

  if (user.purchases && user.purchases.length) {
    user.purchases.forEach((purchase) => {
      if (purchase === event.frontmatter.uid) {
        hasUserPurchasedKit = true;
      }
    });
  }

  let hasInventory =
    event.productInventory &&
    event.productInventory.prices &&
    event.productInventory.prices.length &&
    event.productInventory.prices[0].inStock;
  if (hasInventory == null) hasInventory = false;

  const hasUserRegistered =
    user.events && user.events.some((uid) => uid === event.frontmatter.uid);
  
  let eventHosts = [];

  if (hosts.length > 0 && event.frontmatter.hosts) {
    hosts.forEach((allHost) => {
      if (allHost.childMdx != null) {
        event.frontmatter.hosts.forEach((eventHost) => {
          if (eventHost === allHost.childMdx.frontmatter.title) {
            eventHosts.push(allHost);
          }
        });
      }
    });
  }

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideModal} onExited={onExited}>
        <div className="modal--lg">
          <EventDetails
            user={user}
            event={event}
            hasUserRegistered={hasUserRegistered}
            hasUserPurchasedKit={hasUserPurchasedKit}
            hosts={eventHosts}
            close={hideModal}
          />
        </div>
      </Modal>
    ),
    [user, event, hosts]
  );
  
  return (
    <>
      <div className="flex flex-justify--spaceBetween">
        <div className="">
          <time dateTime="2020-09-03" className="paddingBottom-0 bold">
            {moment(event.frontmatter.time.start).format("dddd, MMMM Do")}
          </time>
          <h2 className="headline2 marginTop-3">{event.frontmatter.title}</h2>
          {isEventLive}
          {/* time related UI */}
        </div>
        <div className="flex--column">
          <Button
            className="button--clean"
            type="button"
            onClick={showModal}
          >
            Details
          </Button>
        </div>
      </div>
      <div className="card-notifications">
        {!hasPurchasingClosed &&
          !isEventLive &&
          !hasUserPurchasedKit &&
          eventHasProduct &&
          hasInventory && (
            <div className="flex align-center">
              {event.frontmatter.category === "livecourses" ? (
                <div className="inventory-label flex align-center marginTop-3 md_marginTop-0">
                  <div className="label bold c-green paddingLeft-2 paddingRight-2">
                    {event.productInventory.prices[0].remaining} left
                  </div>
                  <div className="icon">
                    <Icon name="box" className="c-green" />
                  </div>
                </div>
              ) : (
                <div className="inventory-label flex align-center marginTop-3 md_marginTop-0">
                  <div className="label bold c-green paddingLeft-2 md_paddingLeft-0 md_paddingRight-2">
                    {event.productInventory.prices[0].remaining} left
                  </div>
                  <div className="icon">
                    <Icon name="ticket" className="c-green" />
                  </div>
                </div>
              )}
            </div>
          )}

        {/* upsell */}
        {/* {!isEventLive &&
          !hasEventEnded &&
          !isEventStartingSoon &&
          hasUserRegistered &&
          hasInventory &&
          !hasUserPurchasedKit && (
            <div className="flex marginTop-4 align-center">
              <div>
                <Icon name="box" />
              </div>
              <div className="bold paddingLeft-2">
                Don't forget to pick up your kit!
              </div>
            </div>
          )} */}

        {!isEventLive &&
          !isEventStartingSoon &&
          hasUserPurchasedKit &&
          (event.frontmatter.includesLiveVideo ? <></> : null)
        // <div className="c-green marginTop-4">
        //   FIX - Info about location of event?
        // </div>
        }

        {isEventStartingSoon && !hasEventEnded && !isEventLive && (
          <div className="c-red pulse bold marginTop-3">
            Begins in {timeUntilStartMins} minute
            {timeUntilStartMins === 1 ? "" : "s"}
          </div>
        )}

        {isEventLive &&
          event.frontmatter.includesLiveVideo &&
          event.videoUrl &&
          event.videoUrl.live && (
            <div className="marginTop-4">
              <a
                className="button button--live"
                href={event.videoUrl.live}
                target="_blank"
                rel="noreferrer noopener"
              >
                Live now
              </a>
            </div>
          )}

        {hasEventEnded &&
          event.frontmatter.includesLiveVideo &&
          event.videoUrl &&
          (event.videoUrl.archive ? (
            <div className="marginTop-4 relative">
              <a
                className="button"
                href={event.videoUrl.archive}
                target="_blank"
                rel="noreferrer noopener"
              >
                Watch on YouTube
              </a>
            </div>
          ) : (
            <p className="c-green bold marginTop-4">
              Archive video available soon.
            </p>
          ))}
      </div>
    </>
  );
};

export default EventCard;
