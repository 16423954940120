import React from "react";

import { TAX_RATE } from "../utils/constants";
import { calculateTax, calculateTotal } from "../utils/checkout-helpers";
import { formatAmount } from "../utils/stripe-utils";

export default function TaxReadout({
  className,
  taxedTotal,
  taxFreeTotal = 0,
  hideCardDetails,
  card,
  tax = TAX_RATE,
}) {
  const total = calculateTotal(taxedTotal) + taxFreeTotal;
  return (
    <div className={`${className ? className : null}`}>
      <div className="marginBottom-2">
        Sales Tax: ({(tax * 100).toFixed(1)}%):{" "}
        {formatAmount(calculateTax(taxedTotal))}
      </div>
      {hideCardDetails ? (
        <div>
          Total: {formatAmount(total)} USD
        </div>
      ) : card ? (
        <div>
          Your {card.brand.toUpperCase()} ending in *{card.last4} will be
          charged for <b>{formatAmount(total)} USD</b>.
        </div>
      ) : (
        <div>Getting card details...</div>
      )}
    </div>
  );
}
