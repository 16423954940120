import React, { useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getErrorMessage } from "../utils/helpers";
import { calculateTax } from "../utils/checkout-helpers";
import TaxReadout from "./TaxReadout";
import {
  addUserToEvent,
  getCurrentUserUID,
  submitPayment,
  decrementInventory,
} from "../utils/firebase-utils";
import Collapse from "./Collapse";
import Button from "./Button";
import NumberInput from "./NumberInput";
import Checkbox from "./Checkbox";
import AddPaymentMethod from "./AddPaymentMethod";

const ItemForm = ({ event, onSuccess, user }) => {
  const { stripeProduct } = event;
  // get user data
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [productQuantity, setProductQuantity] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUID())
      .collection("payment_methods")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) console.warn("no card in database");
        setPaymentMethods(snapshot.docs.map((doc) => doc.data()));
      });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const card = selectedCard ? selectedCard : paymentMethods[0].id;
    const uid = await getCurrentUserUID();

    const total = stripeProduct.prices[0].unit_amount * productQuantity;
    const tax = calculateTax(total);
    const amount = total + tax;
    const description = `CCC - ${
      event.frontmatter.title
    } (${productQuantity} item${productQuantity > 1 ? "s" : ""})`;

    const paymentData = {
      payment_method: card,
      currency: "usd",
      amount: amount,
      description: description,
      status: "new",
      metadata: {
        product: stripeProduct.prices[0].id,
        quantity: productQuantity,
        tax,
      },
    };

    const { data: paymentResult } = await submitPayment(
      { itemUID: event.frontmatter.uid, uid, ...user },
      paymentData
    );

    if (paymentResult.error) {
      return handleError(paymentResult.error.code);
    }

    addUserToEvent(user, uid, event.frontmatter.uid, true);
    decrementInventory("events", event.frontmatter.uid, {
      [stripeProduct.prices[0].id]: { quantity: productQuantity },
    });
    onSuccess(); // closes the modal etc
  };

  const currentCard =
    paymentMethods &&
    (paymentMethods.length ? selectedCard ? paymentMethods.find((card) => card.id === selectedCard).card : paymentMethods[0].card  : null);

  const maxUnitsPurchased = Math.min(
    event.frontmatter.product.purchaseLimit ||
      event.productInventory.prices[0].remaining,
    event.productInventory.prices[0].remaining
  );
  return (
    <>
      <div className="border--bottom paddingBottom-4 marginBottom-6">
        <h2 className="headline2 marginBottom-4">Payment</h2>
        {currentCard && (
          <div className="marginBottom-2" style={{ height: "45px" }}>
            <PaymentMethodSelect
              onChange={(_selectedCard) => setSelectedCard(_selectedCard)}
              items={paymentMethods}
              selected={selectedCard}
            />
          </div>
        )}
        <Collapse
          isOpen={!currentCard}
          hideButtonWhenOpen={true}
          buttonContents={() => (
            <div
              type="button"
              className="button button--textonly marginBottom-4"
            >
              Use a different card
            </div>
          )}
          render={(close) => (
            <AddPaymentMethod
              onCancel={close}
              onSuccess={(payment_method) => {
                setSelectedCard(payment_method.id);
                close();
              }}
            />
          )}
        />
      </div>
      <form onSubmit={handleOnSubmit} className="marginBottom-4">
        <div className="border-bottom">
          <h2 className="headline2 marginBottom-6">
            {event.frontmatter.product.purchaseHeadline}
          </h2>
          <p className="marginBottom-6">
            {event.frontmatter.product.purchaseDescription}
          </p>
          <Checkbox
            className="marginBottom-6"
            required
            label="I understand"
            onChange={setHasConfirmed}
          />
        </div>
      </form>
      <div className="marginBottom-6 paddingTop-6 border--top">
        <h2 className="headline2 marginBottom-6">Quantity</h2>
        <div className="flex flex-align--center marginBottom-8">
          <NumberInput
            min={1}
            className="marginRight-4"
            onChange={setProductQuantity}
            defaultValue={1}
            max={maxUnitsPurchased}
          />
          <p className="margin-0">
            Max {maxUnitsPurchased}{" "}
            {event.frontmatter.category !== "livecourses" ? "slot" : "kit"}
            {maxUnitsPurchased > 1 ? "s" : ""}
          </p>
        </div>
      </div>
      <div className="marginBottom-6 marginTop-4 border--top paddingTop-8">
        <TaxReadout
          className=""
          taxedTotal={stripeProduct.prices[0].unit_amount * productQuantity}
          card={currentCard}
        />
        {hasError && <p className="c-red">{hasError}</p>}
        <Button
          type="button"
          className={`${isSubmitting ? "button--loading" : ""} marginTop-8 `}
          disabled={!hasConfirmed || isSubmitting}
          onClick={handleOnSubmit}
        >
          {isSubmitting ? <span>Sending...</span> : "Pay now and register"}
        </Button>
      </div>
    </>
  );
};

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const ItemPurchaseForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <ItemForm {...props} />
    </Elements>
  );
};

const PaymentMethodSelect = ({ items, selected, onChange }) => {
  return (
    <select
      className="paymentMethodSelect padding-3"
      name="paymentMethods"
      id="paymentMethods"
      value={selected}
      onChange={(e) => onChange(e.target.value)}
    >
      {/* loops through users payment methods */}
      {items ? (
        items.map((item, i) => {
          if (!item.card) return undefined;
          let cardBrand = item.card.brand.toUpperCase();
          return (
            <option key={item.id} id={item.id} value={item.id}>
              {cardBrand} ending in *{item.card.last4}
            </option>
          );
        })
      ) : (
        <option value={"loading"}>Loading...</option>
      )}
    </select>
  );
};

export default ItemPurchaseForm;
