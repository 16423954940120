import React, { useState } from "react";

import PasswordResetForm from "./PasswordResetForm";
import Button from "./Button";
import { getUserData } from "../utils/firebase-utils";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import { getErrorMessage } from "../utils/helpers";
import firebase from "gatsby-plugin-firebase";

const ReAuth = ({ email, onSuccess, onCancel }) => {
  const initData = {
    password: "",
  };
  const [formData, setFormData] = useState(initData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Modal 
      open={open} 
      close={() => {
        hideModal()
      }} 
      onExited={onExited}
    >
      <div className="modal--sm">
        <PasswordResetForm onSuccess={hideModal} />
      </div>
    </Modal>
  ));

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (hasError) setHasError(false);
        setIsSubmitting(true);
        var user = firebase.auth().currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, formData.password);
        // Prompt the user to re-provide their sign-in credentials
        user.reauthenticateWithCredential(credentials).then(function() {
          if (onSuccess) onSuccess();
        }).catch(function(error) {
          setIsSubmitting(false);
          return setHasError(getErrorMessage(error.code));
        });
      }}
    >
      <div className="marginBottom-6">
        <h1 className="headline2 marginBottom-6">Confirm your password</h1>
      </div>
      <div className="marginBottom-6">
        <label htmlFor="password">Password</label>
        <input
          className="input-text"
          type="password"
          name="password"
          id="password"
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
          value={formData.password}
        />
      </div>
      {hasError && <p className="c-red bold marginBottom-4">{hasError}</p>}
      <div className="marginBottom-6">
        <button
          disabled={isSubmitting}
          className={`button ${isSubmitting ? "button--loading" : ""}`}
          type="submit"
        >
          {isSubmitting ? <span>Submitting...</span> : "Submit"}
        </button>
      </div>
      <div>
        <Button className="button--textonly c-black" onClick={showModal}>
          Forgot your password?
        </Button>
      </div>
    </form>
  );
};

export default ReAuth;
