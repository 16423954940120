import React from "react";

import Layout from "../components/layout";
import Landing from "../components/Landing";
import Dashboard from "../components/Dashboard";

export const IndexPage = (props) => {
  return (
    <Layout
      location={props.location}
      render={(context) =>
        context.user && context.user.hasEnrolled ? (
          <Dashboard context={context} />
        ) : (
          <Landing />
        )
      }
    />
  );
};

export default IndexPage;
