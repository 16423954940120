import React from "react";

export default function Checkbox({ className, label, onChange, required }) {
  return (
    <div className={`checkbox ${className ? className : ""}`}>
      <input
        className="input-checkbox"
        id="confirmation"
        type="checkbox"
        style={{ display: "none" }}
        onChange={(e) => onChange(e.target.checked)}
        required={required}
      />
      <label className="checkbox" htmlFor="confirmation">
        <span>
          <svg width="12px" height="9px" viewBox="0 0 12 9">
            <polyline points="1 5 4 8 11 1"></polyline>
          </svg>
        </span>
        <span className="bold">{label}</span>
      </label>
    </div>
  );
}
