import React from "react";

import EventCard from "./EventCard";

const EventsList = ({ items, user, hosts, category }) => {
  if (items.length < 1)
    return (
      <ul className="pb3 active list">
        <li className="paddingTop-7 paddingBottom-6">No classes available</li>
      </ul>
    );
  return (
    <ul className="pb3 active list flex flex-wrap space-between">
      {items.map((item) => {
        return (
          <li
            key={item.frontmatter.uid}
            className="card paddingTop-4 md_paddingTop-6"
          >
            <EventCard
              hideDescription={category === "userSelected"}
              user={user}
              event={item}
              hosts={hosts}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default EventsList;
