import React, {useState} from 'react'
import { getErrorMessage } from "../utils/helpers";
import { useModal } from "react-modal-hook";
import Button from './Button'
import Modal from './Modal'
import ReAuth from './ReAuth'
import firebase from "gatsby-plugin-firebase";

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export default function UserInfo({user, onSuccess, onCancel}) {
  const initData = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };

  const [formData, setFormData] = useState(initData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [showReAuth, hideReAuth] = useModal(
    ({ in: open, onExited }) => (
      <Modal 
        open={open} 
        close={() => {
          hideReAuth()
          setIsSubmitting(false);
        } }
        onExited={onExited}
      >
        <div className="modal--lg">
          <ReAuth 
            email={user.email}
            onCancel={() => {
              setIsSubmitting(false);
              hideReAuth()
            }}
            onSuccess={() => {
              hideReAuth();
              handleSubmit();
            }} 
          />
        </div>
      </Modal>
    ),
    [formData]
  );

  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setIsSubmitting(true);
    const currentUser = firebase.auth().currentUser;
    if(formData.email !== initData.email) {
      await currentUser.updateEmail(formData.email).then(async () => {
        console.log('email updated')
        await firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .update({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
          })
          .then(() => {
            console.log("updated user info");
          })
          .catch(error => {
            return handleError(error.code);
          })
          onSuccess(); // closes the modal etc
      }).catch((error) => {
        if(error.code === 'auth/requires-recent-login') {
          return showReAuth()
        }
        return handleError(error.code);
      })
    } else {
      await firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .update({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        })
        .then(() => {
          onSuccess(); // closes the modal etc
        })
        .catch((error) => {
          handleError(error.code);
        });
    }
  };

  const hasDataChanged = !shallowEqual(initData, formData)

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h1 className="headline2 marginBottom-10">Student Profile</h1>
        <div className="marginBottom-6">
          <label htmlFor="firstName" required>
            First name
          </label>
          <input
            className="input-text"
            type="text"
            name="firstName"
            id="firstName"
            required
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
            value={formData.firstName}
          />
        </div>
        <div className="marginBottom-6">
          <label htmlFor="lastName">Last name</label>
          <input
            className="input-text"
            type="text"
            name="lastName"
            id="lastName"
            required
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
            value={formData.lastName}
          />
        </div>
        <div className="marginBottom-6">
          <label htmlFor="email">Email</label>
          <input
            className="input-text"
            type="email"
            name="email"
            id="email"
            required
            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
            title="Please enter a valid email address"
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            value={formData.email}
          />
        </div>
        {hasError && <p className="c-red bold marginBottom-4">{hasError}</p>}
        <div className="flex flex-justify--spaceBetween flex-align--center">
          <Button
            className={`marginTop-4 button button--textonly`}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <button
            disabled={isSubmitting || !hasDataChanged}
            className={`marginTop-4 button ${isSubmitting ? "button--loading" : ""}`}
            type="submit"
          >
            {isSubmitting ? <span>Updating...</span> : "Update"}
          </button>
        </div>
      </form>
    </div>
  )
}
