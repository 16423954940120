import React from "react";

import Tab from "./Tab";

export default function TabView({
  children,
  className,
  activeTab,
  setActiveTab,
}) {
  return (
    <div className={className ? className : ""}>
      <nav className="tab-nav border--bottom marginBottom-3">
        <ul className="flex">
          {children.map(({ props }, i) => {
            if (!props) return undefined;
            return (
              <li key={`tab-${i}`} className="marginRight-2">
                <Tab
                  label={props.label}
                  isActive={activeTab === props.label}
                  onClick={() => setActiveTab(props.label)}
                />
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="tabView-content">
        {children.map((child) => {
          if (!child || child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
}
