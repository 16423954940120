import React, { useState } from "react";
import moment from "moment";
import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  addUserToEvent,
  removeUserFromEvent,
  addUserToWaitList,
  getCurrentUserUID,
} from "../utils/firebase-utils";
import { formatAmount } from "../utils/stripe-utils";
import { minMax } from "../utils/helpers";
import Button from "./Button";
import PersonCard from "./PersonCard";
import Collapse from "./Collapse";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import ItemPurchaseForm from "./ItemPurchaseForm";
import useSnackBars from "../hooks/useSnackBars";
import ProductContents from "./ProductContents";
import CalendarLinks from "./CalendarLinks";
import Icon from "./Icon";

const EventDetails = ({
  event,
  hasUserRegistered,
  hasUserPurchasedKit,
  hosts,
  user,
  close,
}) => {
  const { addAlert } = useSnackBars();
  const [addingToWaitList, setAddingToWaitList] = useState(false);
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideModal} onExited={onExited}>
        <div className="modal--lg">
          <ItemPurchaseForm
            user={user}
            event={event}
            onSuccess={() => {
              hideModal();
              addAlert(`📎 We've emailed you a receipt.`);
            }}
          />
        </div>
      </Modal>
    ),
    [user, event, hasUserRegistered]
  );

  const [showCalendarModal, hideCalendarModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideCalendarModal} onExited={onExited}>
        <div className="modal--lg">
          <CalendarLinks
            user={user}
            event={event}
            onSuccess={() => {
              hideCalendarModal();
            }}
          />
        </div>
      </Modal>
    ),
    [user, event, hasUserRegistered]
  );

  const userHasRequestedNotification =
    user.tags &&
    event.stripeProduct &&
    user.tags
      .map((tag) => tag.priceId)
      .indexOf(event.stripeProduct.prices[0].id) !== -1;

  let hostCards = [];
  let hostsText = "host";

  if (hosts && hosts.length > 0) {
    if (hosts.length > 1) hostsText = "hosts";

    hostCards = hosts.map((host) => (
      <li key={host.childMdx.frontmatter.title}>
        <PersonCard data={host} />
      </li>
    ));
  }

  let price;
  if (
    event.stripeProduct &&
    event.stripeProduct.prices &&
    event.stripeProduct.prices.length > 1
  ) {
    const [low, high] = minMax(
      event.stripeProduct.prices.map((price) => price.unit_amount)
    );
    price =
      low === high
        ? formatAmount(low)
        : `${formatAmount(low)} - ${formatAmount(high)}`;
  } else if (event.stripeProduct) {
    price = `$${event.stripeProduct.prices[0].unit_amount
      .toString()
      .slice(0, -2)}`;
  }

  /* Determine if we are out of stock of the kits. */
  let haveEventKitsLeft =
    event.productInventory &&
    event.productInventory.prices &&
    event.productInventory.prices.length &&
    event.productInventory.prices[0].inStock;

  return (
    <>
      {event.hasEventEnded && (
        <div className="c-red bold marginBottom-4 marginTop-0 paddingTop-0">
          This event has ended
        </div>
      )}
      <div className="marginBottom-5">
        {moment(event.frontmatter.time.start).format("dddd, MMMM Do")} at{" "}
        {moment(event.frontmatter.time.start).format("h:mm A")}
      </div>
      <h1 className="headline1 marginBottom-2">{event.frontmatter.title}</h1>
      {event.frontmatter.partners && (
        <div>In partnership with {event.frontmatter.partners}</div>
      )}
      {event.isEventLive &&
        event.frontmatter.includesLiveVideo &&
        event.videoUrl &&
        event.videoUrl.live && (
          <div className="marginTop-4">
            <a
              className="button button--live"
              href={event.videoUrl.live}
              target="_blank"
              rel="noreferrer noopener"
            >
              Live now
            </a>
          </div>
        )}
      {!event.hasEventEnded &&
        hasUserPurchasedKit &&
        event.frontmatter.category === "livecourses" && (
          <div className="flex marginTop-6 align-center flex-wrap">
            <div className="kit-list border--top">
              <h2 className="headline2 marginBottom-3 marginTop-4 width--100">
                Your kit(s)
              </h2>
              <div className="flex marginTop-4 align-center marginBottom-4">
                <div>
                  <Icon name="box" className="c-green" />
                </div>
                <div className="bold c-green paddingLeft-2">
                  <div className="c-green bold">
                    Don't forget to pick up at Canlis
                  </div>
                </div>
              </div>
              <MDXRenderer>{event.frontmatter.product.contents}</MDXRenderer>
            </div>
          </div>
        )}
      {event.hasEventEnded &&
        event.frontmatter.includesLiveVideo &&
        event.videoUrl &&
        (event.videoUrl.archive ? (
          <div className="marginTop-4 relative marginBottom-6">
            <a
              className="button"
              href={event.videoUrl.archive}
              target="_blank"
              rel="noreferrer noopener"
            >
              Watch video
            </a>
          </div>
        ) : (
          <div className="c-green bold marginTop-4 marginBottom-6">
            Archive video available soon.
          </div>
        ))}
      <div className="marginTop-4">
        {event.stripeProduct && !hasUserPurchasedKit && !event.hasEventEnded && !event.hasPurchasingClosed && (
          <div className="elevated padding-4 md_padding-6 marginTop-0 marginBottom-8 ">
            <h2 className="headline2 marginBottom-3">
              {event.frontmatter.product.headline}
            </h2>
            <div className="marginBottom-4">
              {event.frontmatter.product.description}
            </div>
            {event.frontmatter.product.hasKit === true && (
              <Collapse
                className="marginBottom-4"
                buttonContents={() => "What's in the kit?"}
                buttonClasses={`button--secondary`}
                hideButtonWhenOpen={true}
              >
                <ProductContents mdx={event.frontmatter.product.contents} />
              </Collapse>
            )}
            <div className="flex space-between align-center">
              {haveEventKitsLeft ? (
                <Button className="button" type="button" onClick={showModal}>
                  {event.frontmatter.product.cta && (
                    <>
                      {event.frontmatter.product.cta} for {price}
                    </>
                  )}
                </Button>
              ) : (
                <Button className="button" type="button" disabled={true}>
                  Sold Out
                </Button>
              )}

              {userHasRequestedNotification ? (
                <div>
                  <p className="c-green bold">You're on the waitlist.</p>
                </div>
              ) : (
                <div>
                  {!haveEventKitsLeft && (
                    <Button
                      className="marginTop-4 button--textonly"
                      disabled={addingToWaitList}
                      onClick={async () => {
                        // FIX - add error state
                        setAddingToWaitList(true);
                        await addUserToWaitList(user, {
                          title: event.frontmatter.title,
                          priceId: event.stripeProduct.prices[0].id,
                        });
                        close();
                        addAlert("You are now on the waitlist.");
                        setAddingToWaitList(false);
                      }}
                    >
                      Join waitlist
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {event.frontmatter.includesLiveVideo &&
          !event.hasEventEnded &&
          !event.isEventLive &&
          (hasUserRegistered ? (
            !hasUserPurchasedKit && (
              <div className="marginBottom-8 flex--column sm_flex sm_flex-align--end flex-justify--spaceBetween">
                <div>
                  <Button
                    className="button marginBottom-6 sm_marginBottom-0"
                    type="button"
                    onClick={() => {
                      removeUserFromEvent(event.frontmatter.uid);
                      addAlert("😕 Removed from your courses.");
                    }}
                  >
                    Remove from my courses
                  </Button>
                </div>
                <div>

                  <Button
                    className="button button--secondary"
                    type="button"
                    onClick={async () => {
                      showCalendarModal();
                    }}
                  >
                    Add to calendar
                  </Button>
                </div>
              </div>
            )
          ) : (
            <div className="elevated padding-4 md_padding-6 marginTop-0 marginBottom-8 ">
              <h2 className="headline2 marginBottom-3">Watch for free</h2>
              <div className="marginBottom-4">
                Included in your enrollment. No kit needed, shop on your own, and then join us on the livestream and in the chat room via Youtube.
              </div>
              <div className="flex flex-justify--spaceBetween">
                <Button
                  className="button"
                  type="button"
                  onClick={async () => {
                    addUserToEvent(
                      user,
                      await getCurrentUserUID(),
                      event.frontmatter.uid
                    );
                    addAlert("🎉 Added to your courses.");
                  }}
                >
                  Add to my courses
                </Button>
                <Button
                  className="button button--secondary"
                  type="button"
                  onClick={async () => {
                    showCalendarModal();
                  }}
                >
                  Add to calendar
                </Button>
              </div>
            </div>
          ))}
      </div>
      <h2 className="headline2 border--top paddingTop-4">About</h2>
      <div className="marginTop-4 marginBottom-8">
        {event.frontmatter.description}
      </div>
      {event.frontmatter.howToParticipate && (
        <>
          <h2 className="headline2 border--top paddingTop-4">
            How to participate
          </h2>
          <div className="marginTop-4 marginBottom-8 how-to-participate">
            <MDXRenderer>{event.frontmatter.howToParticipate}</MDXRenderer>
          </div>
        </>
      )}
      {event.isLive && <p className="c-red">LIVE!</p>}
      <h2 className="headline2 border--top paddingTop-4">
        Meet the {hostsText}
      </h2>
      <ul>{hostCards}</ul>
    </>
  );
};

export default EventDetails;
