
import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";

import Button from "../components/Button";
import SignUp from "../components/Signup";
import Login from "../components/Login";
import LowerContent from "../components/LowerContent";
import Modal from "./Modal";
import { useModal } from "react-modal-hook";
import useSnackBars from "../hooks/useSnackBars";

export default function Landing({ context }) {
  const { addAlert } = useSnackBars();
  const [showLoginModal, hideLoginModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideLoginModal} onExited={onExited}>
        <div className="modal--sm">
          <Login
            onSuccess={(user) => {
              addAlert(`Welcome back. 👋`);
            }}
            onCancel={hideLoginModal}
          />
        </div>
      </Modal>
    ), []
  );
  const [showSignupModal, hideSignupModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideSignupModal} onExited={onExited}>
        <div className="modal--lg">
          <SignUp
            onSubmit={() => {
              addAlert("Good news! You are now enrolled.");
              hideSignupModal();
            }}
            onCancel={hideSignupModal}
          />
        </div>
      </Modal>
    )
  );

  // const { filson, hero } = useStaticQuery(graphql`
  //   query {
  //     filson: file(relativePath: { eq: "images/landing/filson.jpg" }) {
  //       ...fluidImage
  //     }

  //     hero: file(relativePath: { eq: "images/landing/hero.jpg" }) {
  //       ...fluidImage
  //     }
  //   }
  // `);

  return (
    <>
      <section className="relative">
        <div className="seal">
          <div className="inner"></div>
          <div className="outer"></div>
        </div>
        <div className="zIndex-1 width--100 md_width--50 padding-6 md_padding-12 lg_padding-16 align-center">
          <h1 className="headline0 marginBottom-5">Class is in session.</h1>
          <p className="marginBottom-8">
            Enrollment is $25 and includes every live course we offer. We’re
            proud to partner with some of Seattle’s finest institutions to bring
            you seven weeks of the best content we could imagine. 100% of your tuition goes to <a href="https://www.farestart.org/" target="_blank">FareStart</a>.
          </p>
          <div className="width--100 flex align-center">
            <Button
              className="button marginRight-5"
              type="button"
              onClick={showSignupModal}
            >
              Enroll now
            </Button>
            <span>Already enrolled?&nbsp;</span>
            <Button
              onClick={showLoginModal}
              className="link--underline cursor-pointer button--textonly"
            >
              Sign in.
            </Button>
          </div>
        </div>
      </section>
      <section>
        <div className="padding-6 md_padding-12 border--top flex flex-wrap align-bottom">
          <div className="width--100 lg_width--66">
            <a
              href="https://www.youtube.com/watch?v=YOcRzVqxpJc"
              target="_blank"
            >
              <img className="border-radius-10 marginBottom-6 md_marginBottom-0" src="https://canlis.com/uploads/crunched.gif"/>
            </a>
          </div>
          <div className="width--100 lg_width--33 md_padding-12 md_paddingBottom-0">
            <h2 className="headline1 marginBottom-3">
              See it all live.
            </h2>
            <p className="marginBottom-4">
              We’re going back to school and we want you to join us. We're celebrating the city we love. This is Seattle, and we’ve got this! Now who wants in?
            </p>
            <a
              href="https://www.youtube.com/watch?v=YOcRzVqxpJc"
              target="_blank"
            >
              <Button
                className="button marginRight-5"
                type="button"
              >
                Watch the trailer
              </Button>
            </a>
            </div>
        </div>
      </section>
      <section>
        <div className="padding-6 md_padding-12 border--top flex flex-wrap align-center">
          <div className="width--100 lg_width--66 lg-order-2">
            <a
              href="https://www.youtube.com/watch?v=uiI8gcQNi7I&t=309s&ab_channel=CanlisCommunityCollege"
              target="_blank"
            >
              <img className="border-radius-10 marginBottom-6 md_marginBottom-0" src="https://canlis.com/uploads/kids.jpg"/>
            </a>
          </div>
          <div className="width--100 lg_width--33 md_padding-12">
            <h2 className="headline1 marginBottom-3">
              Fun for the kids, too!
            </h2>
            <p className="marginBottom-4">
              Join Mark and Brian Canlis as they travel to the Woodland Park Zoo and Seattle Aquarium, and learn just how much wildlife has to teach us.
            </p>
            <a
              href="https://www.youtube.com/watch?v=uiI8gcQNi7I&t=309s&ab_channel=CanlisCommunityCollege"
              target="_blank"
            >
              <Button
                className="button marginRight-5"
                type="button"
                onClick={showSignupModal}
              >
                Watch for free
              </Button>
            </a>
          </div>

        </div>
      </section>
      <section>
        <div className="padding-6 md_padding-12 border--top flex flex-wrap align-center">
          <div className="width-100 lg_width--66">
            <img className="border-radius-10 marginBottom-6 md_marginBottom-0" src="https://canlis.com/uploads/pendant.jpg"/></div>
          <div className="width-100 lg_width--33 md_padding-12">
            <h2 className="headline1 marginBottom-3">
              The Campus Store
            </h2>
            <h3 className="headline2 marginBottom-3">
              Smells like school spirit.
            </h3>
            <p className="marginBottom-4">
              Enroll and shop gear from Filson, Ebbets Field, BlackWing, and much more.
            </p>
            <Button
              className="button marginRight-5"
              type="button"
              onClick={showSignupModal}
            >
              Enroll now
            </Button>
            </div>

        </div>
      </section>
      <section className="block border--bottom padding-6 md_padding-12 border--top lg_padding-16 flex flex-justify--spaceBetween">
        <div className="width--100">
          <h2 className="headline1 marginBottom-5">Partners</h2>
          <ul className="partner-logos flex align-center marginBottom-10">
            <li className="marginRight-6">
              <img src="https://www.glazerscamera.com/skin/frontend/rwd_glazers_2_0/default/img/glazers-logo.png"/>
            </li>
            <li className="marginRight-6">
              <img src="https://canlis.com/uploads/rainier_logo.png"/>
            </li>
            <li className="marginRight-6">
              <img src="https://canlis.com/uploads/WPZ-Logo_black.png"/>
            </li>
            <li className="marginRight-6">
              <img src="https://canlis.com/uploads/Seattle-Aquarium-Logo.png"/>
            </li>
          </ul>
          <div className="flex flex-wrap partner-names">
            <div className="headline2">
              <span>Big Rock Productions</span>
              <span>Burke Museum</span>
              <span>The Bread Lab Collective</span>
              <span>Climate Pledge Arena</span>
              <span>Ebbets Field Flannels</span>
              <span>Filson</span>
              <span>Finnriver Farm and Cidery</span>
              <span>Kamonegi</span>
              <span>KEXP</span>
              <span>Nordstrom</span>
              <span>Northwest African American Museum</span>
              <span>Museum of History & Industry</span>
              <span>Musang</span>
              <span>Space Needle</span>
              <span>Pacific Northwest Ballet</span>
              <span>Pike Place Market</span>
              <span>Rudy's Barbershop</span>
              <span>Seattle Art Museum</span>
              <span>Seattle Kraken</span>
              <span>Taylor Shellfish</span>
              <span>Visit Seattle</span>
              <span>
                Wing Luke Museum of the Asian Pacific American Experience
              </span>
            </div>
            <div className="marginTop-10">
              Space Needle® (use of Space Needle approved by Space Needle LLC)
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
