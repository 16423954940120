import React, { useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getErrorMessage } from "../utils/helpers";
import {
  getCurrentUserUID,
  submitPayment,
  getUserData,
  decrementInventory,
} from "../utils/firebase-utils";
import Collapse from "./Collapse";
import Button from "./Button";
import Checkbox from "./Checkbox";
import AddPaymentMethod from "./AddPaymentMethod";
import TaxReadout from "./TaxReadout";
import { calculateTax } from "../utils/checkout-helpers";

const ItemForm = ({ product, order, onSuccess }) => {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUID())
      .collection("payment_methods")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) console.log("no card in database");
        setPaymentMethods(snapshot.docs.map((doc) => doc.data()));
      });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const card = selectedCard ? selectedCard : paymentMethods[0].id;
    const uid = await getCurrentUserUID();
    const user = await getUserData(uid);
    const variations = {};
    Object.values(order.items).forEach(
      (item) => (variations[item.variationName] = item.quantity)
    );
    const tax = calculateTax(order.total);
    const amount = order.total + tax;

    const data = {
      payment_method: card,
      currency: "usd",
      amount: amount,
      description: `CCC - ${product.frontmatter.title}`,
      status: "new",
      metadata: {
        tax,
        product: order.product,
        ...variations,
      },
    };

    const { data: paymentResult } = await submitPayment({ uid, ...user }, data);
    decrementInventory("cafeteriaItems", product.frontmatter.uid, order.items);

    if (paymentResult.error) {
      return handleError(paymentResult.error.code);
    }

    onSuccess(); // closes the modal etc
  };

  const currentCard =
    paymentMethods &&
    (paymentMethods.length ? selectedCard ? paymentMethods.find((card) => card.id === selectedCard).card : paymentMethods[0].card  : null);

  return (
    <>
      <form onSubmit={handleOnSubmit} className="marginBottom-4">
        <div className="border--bottom paddingBottom-4 marginBottom-6">
          <h2 className="headline2 marginBottom-4">Payment</h2>
          {currentCard && (
            <div className="marginBottom-2" style={{ height: "45px" }}>
              <PaymentMethodSelect
                onChange={(_selectedCard) => setSelectedCard(_selectedCard)}
                items={paymentMethods}
                selected={selectedCard}
              />
            </div>
          )}
          <Collapse
            isOpen={!currentCard}
            hideButtonWhenOpen={true}
            buttonContents={() => (
              <div
                type="button"
                className="button button--textonly marginBottom-4"
              >
                Use a different card
              </div>
            )}
            render={(close) => (
              <AddPaymentMethod
                onCancel={close}
                onSuccess={(payment_method) => {
                  setSelectedCard(payment_method.id);
                  close();
                }}
              />
            )}
          />
        </div>
        <div className="border-bottom">
          <h2 className="headline2 marginBottom-6">
            {product.frontmatter.product.purchaseHeadline}
          </h2>
          <p className="marginBottom-6">
            {product.frontmatter.product.purchaseDescription}
          </p>
          <Checkbox
            className="marginBottom-6"
            required
            label="I understand"
            onChange={setHasConfirmed}
          />
        </div>
      </form>
      <div className="marginBottom-6 marginTop-4 border--top paddingTop-8">
        <TaxReadout
          className="marginBottom-8"
          taxedTotal={order.total}
          card={currentCard}
        />
        {hasError && <p className="c-red">{hasError}</p>}
        <Button
          type="button"
          className={`${isSubmitting ? "button--loading" : ""}`}
          disabled={!hasConfirmed || isSubmitting}
          onClick={handleOnSubmit}
        >
          {isSubmitting ? <span>Sending...</span> : "Submit Payment"}
        </Button>
      </div>
    </>
  );
};

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const ItemPurchaseForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <ItemForm {...props} />
    </Elements>
  );
};

const PaymentMethodSelect = ({ items, selected, onChange }) => {
  return (
    <select
      className="paymentMethodSelect padding-3"
      name="paymentMethods"
      id="paymentMethods"
      value={selected}
      onChange={(e) => onChange(e.target.value)}
    >
      {/* loops through users payment methods */}
      {items ? (
        items.map((item, i) => {
          if (!item.card) return undefined;
          let cardBrand = item.card.brand.toUpperCase();
          return (
            <option key={item.id} id={item.id} value={item.id}>
              {cardBrand} ending in *{item.card.last4}
            </option>
          );
        })
      ) : (
        <option value={"loading"}>Loading...</option>
      )}
    </select>
  );
};

export default ItemPurchaseForm;
