import React from "react";

import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import Button from "./Button";
import CafeteriaItemDetails from "./CafeteriaItemDetails";

export default function CafeteriaItemCard({ item, user }) {
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideModal} onExited={onExited}>
        <div className="modal--lg">
          <CafeteriaItemDetails close={hideModal} user={user} item={item} />
        </div>
      </Modal>
    ),
    [user, item]
  );

  return (
    <div
      className="flex flex-justify--spaceBetween"
      style={{ cursor: "pointer" }}
    >
      <div className="paddingRight-10">
        <h2 className="headline2">
          {item.frontmatter.title}
        </h2>
      </div>
      <div className="flex--column">
        <Button className="button--clean" type="button" onClick={showModal}>
          Details
        </Button>
      </div>
    </div>
  );
}
