import React from "react";
import Collapse from "./Collapse";
import Icon from "./Icon";

export default function CollapseSection({ title, children, isOpen }) {
  return (
    <Collapse
      isOpen={isOpen}
      buttonClasses={"width--100"}
      buttonContents={(isOpen) => (
        <div className="padding-6 md_padding-10 lg_padding-16 flex flex-justify--spaceBetween flex-align--center">
          <h1 className="headline1">{title}</h1>
          <div
            className={`relative ${isOpen ? "rotateX-180" : ""}`}
            style={{ transition: "transform 200ms ease" }}
          >
            <Icon name="expandArrow" className="expand-arrow absolute" />
          </div>
        </div>
      )}
    >
      <div className="padding-6 paddingTop-0 md_padding-10 lg_padding-16 lg_paddingTop-0 md_paddingTop-0">
        {children}
      </div>
    </Collapse>
  );
}
