import React, { useState } from "react";
import { getErrorMessage } from "../utils/helpers";
import {
  createUserData,
  ammendCurrentUserData,
  sendPasswordReset,
} from "../utils/firebase-utils";
import firebase from "gatsby-plugin-firebase";

const AddFreeUser = ({ onSuccess }) => {
  const initData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "willsendresetemail",
  };

  const [formData, setFormData] = useState(initData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const userData = formData;

    // const { user, error: userError } = await createUser(formData); // creates customer in stripe
    await firebase
      .functions()
      .httpsCallable("createFreeUser")(userData)
      .then(async (res) => {
        console.log("user created:", res.data);
        userData.uid = res.data.uid;
        userData.hasEnrolled = true;
        await createUserData(userData);
        await firebase.functions().httpsCallable("createStripeCustomer")(
          userData
        );
      })
      .catch(console.error);

    sendPasswordReset(userData.email);
    // set hasEnrolled to be true which will trigger access to dashboard
    onSuccess(userData); // closes the modal etc
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <h1 className="headline2 marginBottom-10">Free User Enrollment</h1>
      <div className="marginBottom-6">
        <label htmlFor="firstName" required>
          First name
        </label>
        <input
          className="input-text"
          type="text"
          name="firstName"
          id="firstName"
          required
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
          value={formData.firstName}
        />
      </div>
      <div className="marginBottom-6">
        <label htmlFor="lastName">Last name</label>
        <input
          className="input-text"
          type="text"
          name="lastName"
          id="lastName"
          required
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
          value={formData.lastName}
        />
      </div>
      <div className="marginBottom-6">
        <label htmlFor="email">Email</label>
        <input
          className="input-text"
          type="email"
          name="email"
          id="email"
          required
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
          title="Please enter a valid email address"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
        />
      </div>

      {hasError && <p className="c-red">{hasError}</p>}

      <button
        disabled={isSubmitting}
        className={`button ${isSubmitting ? "button--loading" : ""}`}
        type="submit"
      >
        {isSubmitting ? <span>Adding...</span> : "Add User"}
      </button>
    </form>
  );
};

export default AddFreeUser;
