import { useEffect, useState } from "react";

// const getSecondsUntilStart = (start) =>
//   Math.floor((new Date(start) - new Date()) / 1000);
const checkIslive = (start, end, now = new Date()) => start < now && end > now;
const checkHasEnded = (end, now = new Date()) => end < now;
const getMinutesUntilStart = (start) =>
  Math.floor((new Date(start) - new Date()) / (1000 * 60));
const checkIsEventStartingSoon = (start) => getMinutesUntilStart(start) <= 60;

const EventTimer = (event) => {
  /* Determine if the user has purchased a kit. */
  const eventStart = new Date(event.frontmatter.time.start);
  const eventEnd = new Date(event.frontmatter.time.end);
  const purchaseEnd = new Date(event.frontmatter.product.purchaseEnd);

  const [eventState, setEventState] = useState({
    isEventLive: checkIslive(eventStart, eventEnd),
    hasEventEnded: checkHasEnded(eventEnd),
    timeUntilStartMins: getMinutesUntilStart(eventStart),
    isEventStartingSoon: checkIsEventStartingSoon(eventStart),
    hasPurchasingClosed: checkHasEnded(purchaseEnd),
  });

  const updateEventState = (obj) => {
    setEventState({
      ...eventState,
      ...obj,
    });
  };

  /* Check for the inventoryAmount property to determine if we have kits available. */
  let hasInventory =
    event.productInventory &&
    event.productInventory.prices &&
    event.productInventory.prices.length &&
    event.productInventory.prices[0].inStock;
  if (hasInventory == null) hasInventory = false;

  const checkEventStatus = () => {
    if (checkHasEnded(eventEnd)) {
      if (!eventState.hasEventEnded) {
        updateEventState({
          hasEventEnded: true,
          isEventLive: false,
          isEventStartingSoon: false,
        });
      }
    } else {
      if (getMinutesUntilStart(eventStart) > 0) {
        // Check to see if the event is starting within the next hour.
        if (checkIsEventStartingSoon(eventStart)) {
          updateEventState({
            isEventStartingSoon: true,
            timeUntilStartMins: getMinutesUntilStart(eventStart),
          });
        }
      } else {
        if (!eventState.isEventLive) {
          updateEventState({
            isEventLive: true,
            isEventStartingSoon: false,
            hasEventEnded: false,
          });
        }
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      checkEventStatus();
    }, 1000);

    // Clear timeout if the component is unmounted.
    return () => clearTimeout(timer);
  }, []);
  return eventState;
};

export default EventTimer;
