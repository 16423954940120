import React, {useState, useMemo} from "react";
import { useStaticQuery, graphql } from "gatsby";

import Button from "./Button";
import CollapseSection from "./CollapseSection";
import CafeteriaItemCard from "./CafeteriaItemCard";
import LowerContent from "../components/LowerContent";
import AddFreeUser from "../components/AddFreeUser";
import SendEventReminder from "../components/SendEventReminder";
import GetUsersByEvent from "../components/GetUsersByEvent";

import Modal from "./Modal";
import { createBatchUsers, getAllUsers } from "../utils/firebase-utils";
import { useModal } from "react-modal-hook";
import useSnackBars from "../hooks/useSnackBars";
import DashboardEvents from "./DashboardEvents";

let batchUsers;
// fill out on account with isAdmin=true to submit batch users
// batchUsers = [
//   {
//     email: "joshuaruledobson@gmail.com",
//     firstName: "Josh",
//     lastName: "Dobson",
//   },
// ];

export default function Dashboard({ context }) {
  const user = context.user;
  const [showAddUser, hideAddUser] = useModal(({ in: open, onExited }) => (
    <Modal open={open} close={hideAddUser} onExited={onExited}>
      <div className="modal--sm">
        <AddFreeUser
          onSuccess={(user) => {
            addAlert(`${user.firstName} is gonna get wicked smart 💥`);
            hideAddUser();
          }}
          onCancel={hideAddUser}
          />
      </div>
    </Modal>
  ));
  const [batchIsSending, setBatchIsSending] = useState();

  const { addAlert } = useSnackBars();

  const query = useStaticQuery(graphql`
  query EventsQuery {
    events: allMdx(
      filter: { frontmatter: { templateKey: { eq: "events" } } }
      sort: { fields: frontmatter___time___start }
      ) {
        nodes {
          ...eventFields
        }
      }
      cafeteriaItems: allMdx(
        filter: { frontmatter: { templateKey: { eq: "cafeteriaItems" } } }
        sort: { fields: frontmatter___product___purchaseEnd }
        ) {
          nodes {
            ...cafeteriaItemFields
          }
        }
        products: allStripePrice {
          groups: group(field: product___id) {
            prices: nodes {
              id
              unit_amount
              description: nickname
            }
            id: fieldValue
          }
        }
        hosts: allFile(
          filter: { sourceInstanceName: { eq: "hosts" }, extension: { eq: "md" } }
          ) {
            nodes {
              childMdx {
                frontmatter {
                  avatar {
                    image {
                      childImageSharp {
                        fluid {
                          sizes
                          src
                          srcSet
                    }
                  }
                }
                alt
              }
              bio
              jobTitle
              title
            }
          }
        }
      }
      partners: allFile(
        filter: {
          sourceInstanceName: { eq: "partners" }
          extension: { eq: "md" }
        }
        ) {
          nodes {
            childMdx {
              frontmatter {
                title
                url
                logo {
                  alt
                  image {
                    childImageSharp {
                      fluid {
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
        categories: allMdx(
          filter: { frontmatter: { templateKey: { eq: "category" } } }
          sort: { fields: frontmatter___order, order: ASC }
          ) {
            nodes {
              fields {
                slug
              }
              frontmatter {
                title
                description
              }
            }
          }
        }
        `);
  const cafeteriaInventory = context.cafeteriaInventory;

  const [showSendEventReminder, hideSendEventReminder] = useModal(  ({ in: open, onExited }) => (
    <Modal open={open} close={hideSendEventReminder} onExited={onExited}>
      <div className="modal--sm">
        <SendEventReminder
          events={query.events.nodes}
          onSuccess={(user) => {
            addAlert(`They have been reminded`);
            hideSendEventReminder();
          }}
          onCancel={hideSendEventReminder}
          />
      </div>
    </Modal>
  ));

  const [showGetUsersByEvent, hideGetUsersByEvent] = useModal(  ({ in: open, onExited }) => (
    <Modal open={open} close={hideGetUsersByEvent} onExited={onExited}>
      <div className="modal--md">
        <GetUsersByEvent
          events={query.events.nodes}
          onSuccess={(user) => {
            addAlert(`Link in console`);
            hideGetUsersByEvent();
          }}
          onCancel={hideGetUsersByEvent}
          />
      </div>
    </Modal>
  ));

  const cafeteriaItemsWithProducts = query.cafeteriaItems.nodes
    .filter((item) => item.frontmatter.product.stripeID)
    .map((item) => {
      item.productInventory = cafeteriaInventory[item.frontmatter.uid];
      item.stripeProduct = query.products.groups.find(
        (product) => product.id === item.frontmatter.product.stripeID
      );
      return item;
    });


  return (
    <div>
      {user.isAdmin && (
        <div className="marginLeft-10 marginRight-10 marginTop-4 marginBottom-4 flex flex-justify--end">
          <Button className="marginTop-2" onClick={showAddUser}>
            Add Free User
          </Button>
          <Button
            className="marginTop-2 marginLeft-4"
            onClick={showSendEventReminder}
          >
            Send Event Reminders
          </Button>
          <Button
            className="marginTop-2 marginLeft-4"
            onClick={getAllUsers}
          >
            Get all users
          </Button>
          {batchUsers && process.env.NODE_ENV !== "production" &&
            <Button
              disabled={batchIsSending}
              className="marginTop-2 marginLeft-4"
              onClick={async () => {
                setBatchIsSending(true);
                await createBatchUsers(batchUsers);
                setBatchIsSending(false);
              }}
            >
              Submit Batch
            </Button>
          }
          <Button
            className="marginTop-2 marginLeft-4"
            onClick={showGetUsersByEvent}
          >
            Get Users By Event
          </Button>
        </div>
      )}

      <DashboardEvents events={query.events.nodes} user={user} hosts={query.hosts.nodes} categories={query.categories.nodes} firebaseData={context.eventsData} products={query.products.groups}/>

      {cafeteriaItemsWithProducts.length > 0 && (
        <section className="block border--bottom">
          <CollapseSection isOpen={true} title="TV Dinners">
            <ul className="active list flex flex-wrap space-between border--top marginTop-6 paddingTop-3">
              {cafeteriaItemsWithProducts.map((item) => (
                <li
                  key={item.fields.slug}
                  className="card paddingTop-4 md_paddingTop-6"
                >
                  <CafeteriaItemCard
                    key={item.frontmatter.title}
                    item={item}
                    user={user}
                  />
                </li>
              ))}
            </ul>
          </CollapseSection>
        </section>
      )}
      <section className="block border--bottom">
        <CollapseSection isOpen={true} title="Office Hours">
          <ul className="pb3 active list flex flex-wrap space-between border--top marginTop-6 paddingTop-3">
            <div className="width--100 marginBottom-6 card">
              <h2 className="headline2 marginBottom-3">
                Nelson Daquip, our Wine Director
              </h2>
              <p className="marginBottom-4">
                Wondering your grocer’s wine aisle wondering what to buy? Or
                need advice on which bottle to pull from your cellar? You’ve got
                a James Beard Award-Winning sommelier in your corner: Nelson is
                available to answer your questions.{" "}
              </p>
              <p className="bold">
                Call or text Nelson at{" "}
                <a href="tel:+2062227253">(206) 222- 7253</a>. 10/13, 10/27, and
                11/10 from 5:00pm to 6:00 pm.{" "}
              </p>
            </div>
            <div className="width--100 card">
              <h2 className="headline2 marginBottom-3">
                Two Bartenders on a Bench
              </h2>
              <p className="marginBottom-4">
                Our Head Barman James MacWilliams will be sitting on a bench
                with other legendary Seattle bartenders to answer all your booze
                and cocktail questions. Subscribe to our Youtube channel to
                watch and ask them a question.{" "}
                <i>Two Bartenders will air 10/6 and 10/20 at 5pm.</i>
              </p>
              <a
                href="https://www.youtube.com/c/Canlis"
                target="_blank"
                rel="external nofollow noopener noreferrer"
                className="button"
              >
                Subscribe
              </a>
            </div>
            <div className="width--100 card">
              <h2 className="headline2 marginBottom-3">Mark & Brian Canlis</h2>
              <p className="marginBottom-4">
                Continuing education, corporate consulting or coaching for you
                or your team.
              </p>
              <a
                href="mailto:office@canlis.com"
                target="_blank"
                rel="external nofollow noopener noreferrer"
                className="button"
              >
                Email us
              </a>
            </div>
          </ul>
        </CollapseSection>
      </section>
      <LowerContent isSignedIn={true} />
    </div>
  );
}
