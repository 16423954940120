import firebase from "gatsby-plugin-firebase";
import React, { useState, useMemo } from "react";
import { getErrorMessage } from "../utils/helpers";
import {getUsersByEvent, getEventByUID} from "../utils/firebase-utils";
import Select from "./Select";
import Button from "./Button";

const GetUsersByEvent = ({onSuccess, events}) => {
  
  const items = useMemo(() => events.map(event =>  ({"value": event.frontmatter.uid, "label": event.frontmatter.title})), events);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState(items[0].value);
  const [hasError, setHasError] = useState(false);
  
  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };
  
  const handleSubmit = async (e, collection) => {
    e.preventDefault();
    setIsSubmitting(true);
    const {data} = await firebase.functions().httpsCallable("usersByEvent")({event: selected, collection});
    console.log(data);
    setIsSubmitting(false);
    onSuccess('success');
  };

  const getUsersForUpsell = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const attendees = await firebase.functions().httpsCallable("usersByEvent")({event: selected, collection: 'attendees'});
    const registrants = await firebase.functions().httpsCallable("usersByEvent")({event: selected, collection: 'registrants'});
    const registrantsEmail = registrants.data.map(registrant => registrant.email);
    const users = attendees.data.filter(attendee => registrantsEmail.indexOf(attendee.email) === -1);
    console.log(users);
    setIsSubmitting(false);
    onSuccess('success');
  };
  
  return (
    <form>
      <h1 className="headline2 marginBottom-10">Get Users By Event</h1>
      <div className="marginBottom-6">
        <Select 
          className="width--100" 
          items={items}
          selected={selected}
          onChange={(e) => setSelected(e.target.value)} 
        />
      </div>
      {hasError && <p className="c-red">{hasError}</p>}
      <div className="flex flex-justify--end">
        <Button
          disabled={isSubmitting}
          className={`button marginRight-4 ${isSubmitting ? "button--loading" : ""}`}
          type="submit"
          onClick={(e) => handleSubmit(e, 'attendees')}
        >
          {isSubmitting ? <span>Fetching...</span> : "Get All"}
        </Button>
        <Button
          disabled={isSubmitting}
          className={`button marginRight-4 ${isSubmitting ? "button--loading" : ""}`}
          type="submit"
          onClick={getUsersForUpsell}
        >
          {isSubmitting ? <span>Fetching...</span> : "Get Attendees for upsell"}
        </Button>
        <Button
          disabled={isSubmitting}
          className={`button ${isSubmitting ? "button--loading" : ""}`}
          type="submit"
          onClick={(e) => handleSubmit(e, 'registrants')}
        >
          {isSubmitting ? <span>Fetching...</span> : "Get Registrants"}
        </Button>
      </div>
    </form>
  );
};

export default GetUsersByEvent;
