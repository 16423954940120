import React from 'react'
import EventsCategory from "./EventsCategory";

const getEventsByCategory = (categories, items) => {
  return categories.map((cat) => {
    cat.items = items.filter((item) => {
      return `/${item.frontmatter.category}/` === cat.fields.slug;
    });
    return cat;
  });
};

export default function EventCategories({categories, events, user, hosts, inventory, products}) {

  const eventsByCategory = getEventsByCategory(categories, events);

  return (
    eventsByCategory.map(category => (
      <EventsCategory
        isOpen={true}
        key={category.fields.slug}
        title={category.frontmatter.title}
        description={category.frontmatter.description}
        user={user}
        items={category.items}
        hosts={hosts}
        inventory={inventory}
        products={products}
        isCategory={true}
      />
    ))
  )
}
