import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { formatAmount } from "../utils/stripe-utils";
import { getErrorMessage } from "../utils/helpers";
import { calculateTotal } from "../utils/checkout-helpers";
import {
  createUser,
  addUserPaymentMethod,
  submitPayment,
  ammendCurrentUserData,
  deleteCurrentUser,
  checkCurrentUser,
} from "../utils/firebase-utils";
import Checkbox from "./Checkbox";
import TaxReadout from "./TaxReadout";

const DONATION_AMOUNT = 450;

const SignupForm = ({ user, onSubmit }) => {
  // get registration item from stripe graphql
  const stripeQuery = useStaticQuery(graphql`
    query SignUpQuery {
      registrationPriceData: stripePrice(
        product: { id: { eq: "prod_I8F6bGmMFKAKkC" } }
      ) {
        unit_amount
        product {
          name
        }
        id
      }
    }
  `);

  const initData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const registrationPrice = parseInt(
    stripeQuery.registrationPriceData.unit_amount
  );

  const [formData, setFormData] = useState(initData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDonating, setIsDonating] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  let cardElement = null;
  let total = registrationPrice;
  // const resetForm = () => setFormData(initData);

  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };

  if (elements) {
    cardElement = elements.getElement(CardElement);
  }

  const handleSubmit = async (event, onSubmit) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setIsSubmitting(true);
    const donation = isDonating ? DONATION_AMOUNT : 0;
    const amount = calculateTotal(total) + donation;

    if (cardElement._empty) {
      return handleError("card/empty");
    }

    const { user, error: userError } = await createUser(formData); // creates customer in stripe
    if (userError) {
      handleError(userError.code);
      if (checkCurrentUser()) {
        deleteCurrentUser();
      }
      return false;
    }

    const { setupIntent, error } = await stripe.confirmCardSetup(
      user.setup_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${user.fullName}`,
          },
        },
      }
    );
    if (error) {
      deleteCurrentUser();
      return handleError(error.code);
    }

    const paymentMethod = await addUserPaymentMethod(
      user.uid,
      setupIntent.payment_method
    );

    const data = {
      payment_method: paymentMethod.id,
      currency: "usd",
      amount: amount,
      description: "Canlis Community College Enrollment",
      status: "new",
      metadata: {
        product: stripeQuery.registrationPriceData.id,
        donation: isDonating,
      },
    };

    const { data: paymentResult } = await submitPayment(user, data);

    if (paymentResult.error) {
      deleteCurrentUser();
      return handleError(paymentResult.error.code);
    }

    // set hasEnrolled to be true which will trigger access to dashboard
    onSubmit(); // closes the modal etc
    ammendCurrentUserData({ hasEnrolled: true });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
      <h1 className="headline2 marginBottom-10">Enrollment</h1>
      <div className="marginBottom-6">
        <label htmlFor="firstName" required>
          First name
        </label>
        <input
          className="input-text"
          type="text"
          name="firstName"
          id="firstName"
          required
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
          value={formData.firstName}
        />
      </div>
      <div className="marginBottom-6">
        <label htmlFor="lastName">Last name</label>
        <input
          className="input-text"
          type="text"
          name="lastName"
          id="lastName"
          required
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
          value={formData.lastName}
        />
      </div>
      <div className="marginBottom-6">
        <label htmlFor="email">Email</label>
        <input
          className="input-text"
          type="email"
          name="email"
          id="email"
          required
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
          title="Please enter a valid email address"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
        />
      </div>
      <div className="marginBottom-8">
        <label htmlFor="password">Password</label>
        <input
          className="input-text"
          type="password"
          name="password"
          id="password"
          required
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
          value={formData.password}
        />
      </div>
      <h1 className="headline2 marginBottom-10">Payment</h1>
      <div className="marginBottom-8">
        <div className="border--bottom paddingBottom-3">
          <CardElement
            onChange={(e) => {
              if (e.complete) setCardComplete(true);
            }}
          />
        </div>
      </div>
      <h1 className="headline2 marginBottom-8">Donation</h1>
      <p className="marginBottom-6">
        Since the pandemic began, FareStart has produced 1.3 million emergency
        meals, to area families. The cost of each meal? $4.50. Let's keep the
        program going.
      </p>
      <Checkbox
        className="marginBottom-8 border--bottom paddingBottom-6 marginBottom-6"
        onChange={(val) => {
          setIsDonating(val);
        }}
        label={`Donate ${formatAmount(DONATION_AMOUNT)} to Farestart`}
      />

      <TaxReadout
        className="marginBottom-8"
        taxedTotal={total}
        taxFreeTotal={isDonating ? DONATION_AMOUNT : 0}
        hideCardDetails
      />

      {hasError && <p className="c-red">{hasError}</p>}

      <button
        disabled={!stripe || isSubmitting || !cardComplete}
        className={`button ${isSubmitting ? "button--loading" : ""}`}
        type="submit"
      >
        {isSubmitting ? <span>Enrolling...</span> : "Pay and enroll"}
      </button>
      <p className="marginTop-8 marginBottom-0 paddingBottom-0">
        By enrolling, you consent to both our <br />
        <a className="link--underline" href="/privacy" target="_blank">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a className="link--underline" href="/terms" target="_blank">
          Terms of Use
        </a>
        .
      </p>
    </form>
  );
};

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const SignUp = ({ onSubmit }) => {
  return (
    <Elements stripe={stripePromise}>
      <SignupForm onSubmit={onSubmit} />
    </Elements>
  );
};

export default SignUp;
