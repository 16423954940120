import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";

export default function ProductContents({ mdx }) {
  return (
    <div className="kit-list border--top border--bottom marginBottom-3 marginBottom-3 paddingBottom-3 paddingTop-3">
      <MDXRenderer>{mdx}</MDXRenderer>
    </div>
  );
}
