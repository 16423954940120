import React, { useState } from "react";
import { formatAmount } from "../utils/stripe-utils";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import NumberInput from "./NumberInput";
import Icon from "./Icon";
import CafeteriaPurchaseForm from "./CafeteriaPurchaseForm";
import Button from "./Button";
import useSnackBars from "../hooks/useSnackBars";
import { addUserToWaitList } from "../utils/firebase-utils";

export default ({ item, user, close }) => {
  const { addAlert } = useSnackBars();
  const [addingToWaitList, setAddingToWaitList] = useState(false);
  const [order, setOrder] = useState({
    product: item.stripeProduct.id,
    total: 0,
    items: null,
  });
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal open={open} close={hideModal} onExited={onExited}>
        <div className="modal--lg">
          <CafeteriaPurchaseForm
            order={order}
            product={item}
            user={user}
            onSuccess={() => {
              addAlert("Success! Now pick out your favorite show!");
              close();
              hideModal();
            }}
          />
        </div>
      </Modal>
    ),
    [order, item, user]
  );
  return (
    <div>
      <h1 className="marginBottom-8 headline2">{item.frontmatter.title}</h1>
      <p className="marginBottom-8">{item.frontmatter.description}</p>
      <ul className="marginBottom-8 border--bottom">
        {item.stripeProduct.prices.map((price) => {
          if (!item.productInventory.prices) return;
          const inventory = item.productInventory.prices.find(
            (dbPrice) => dbPrice.id === price.id
          );
          const priceInfo = item.frontmatter.product.prices.find(
            (cmsPrice) => cmsPrice.id === price.id
          );
          const userHasRequestedNotification =
            user.tags &&
            user.tags.map((tag) => tag.priceId).indexOf(price.id) !== -1;
          return (
            // <Variant inventory={inventory} priceInfo={priceInfo} userHasRequestedNotification={userHasRequestedNotification} price={price} setOrder={setOrder} order={order}>
            <div
              key={price.id}
              className="border--top paddingTop-6 flex marginBottom-6 space-between"
            >
              <div>
                <h2 className="headline2 marginBottom-2">{priceInfo.title}</h2>
                <p className="marginBottom-2">{priceInfo.description}</p>
                <div className="bold">{formatAmount(price.unit_amount)}</div>
                {inventory.inStock ? (
                  inventory.remaining < 25 && (
                    <div className="flex marginTop-2">
                      <div>
                        <Icon name="ticket" className="c-green" />
                      </div>
                      <div className="bold c-green paddingLeft-2">
                        {inventory.remaining} item
                        {inventory.remaining > 1 ? "s" : ""} available
                      </div>
                    </div>
                  )
                ) : (
                  <div className="c-red marginTop-4">Sold Out :(</div>
                )}
              </div>
              <div className="flex-column flex-align--end">
                {inventory.inStock ? (
                  <>
                    <NumberInput
                      className="marginLeft-8 marginBottom-2"
                      min={0}
                      defaultValue={0}
                      max={inventory.remaining}
                      onChange={(val) =>
                        setOrder(calculateOrder(price, val, order))
                      }
                    />
                    {/* show max items when counter reaches inventory amount */}
                    {order.items &&
                      order.items[price.id] &&
                      order.items[price.id].quantity ===
                        inventory.remaining && (
                        <span className="c-grey">
                          Max {inventory.remaining} item
                          {inventory.remaining > 1 ? "s" : ""}
                        </span>
                      )}
                  </>
                ) : userHasRequestedNotification ? (
                  <div className="c-green bold">
                    You are now on the waitlist.
                  </div>
                ) : (
                  <Button
                    className="marginTop-4 button--textonly"
                    disabled={addingToWaitList}
                    onClick={async () => {
                      setAddingToWaitList(true);
                      await addUserToWaitList(user, {
                        title: `${item.frontmatter.title}:${priceInfo.title}`,
                        priceId: price.id,
                      });
                      addAlert(
                        `🎉 You are now on the waitlist for ${priceInfo.title}`
                      );
                      close();
                      setAddingToWaitList(false);
                    }}
                  >
                    {addingToWaitList ? "Adding..." : "Join waitlist"}
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </ul>
      <div className="flex space-between align-center">
        <Button disabled={!order.total} onClick={showModal}>
          Checkout
        </Button>
        <div className="bold">{formatAmount(order.total)}</div>
      </div>
    </div>
  );
};

const calculateOrder = (price, quantity, order) => {
  const itemTotal = price.unit_amount * quantity;
  const currentItems = {
    ...order.items,
    [price.id]: { quantity, variationName: price.description, itemTotal },
  };
  const total = Object.values(currentItems)
    .map((x) => x.itemTotal)
    .reduce((acc, currentItem) => {
      return acc + currentItem;
    });

  return {
    ...order,
    total,
    items: currentItems,
  };
};
