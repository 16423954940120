import React from 'react'
import moment from 'moment'
import { google, outlook, office365, yahoo } from "calendar-link";

export default function CalendarLinks({user, event}) {

  const timeStart = moment(event.frontmatter.time.start);
  const timeEnd = moment(event.frontmatter.time.end);
  const duration = moment.duration(timeEnd.diff(timeStart));
  const durationInHours = duration.asHours();
  // const pickupEnd = moment(event.product.pickupEnd);
  // const pickupStart = moment(event.product.pickupStart);
  // const timeStartLocal = timeStart
  //   .tz("America/Los_Angeles")
  //   .format("dddd, MMMM Do, h:mm a");
  // const pickupStartLocal = pickupStart
  //   .tz("America/Los_Angeles")
  //   .format("dddd, MMMM Do, h:mm a");
  // const pickupEndLocal = pickupEnd
  //   .tz("America/Los_Angeles")
  //   .format("h:mm a");
  const eventData = {
    title: `${event.frontmatter.title}`,
    description: `To join us live, go to <a href="https://canlis.com">https://canlis.com</a>, sign into your account, and tap the live button for ${event.frontmatter.title}.`,
    start: timeStart,
    duration: [durationInHours, "hour"]
  };

  return (
    <div>
      <h1 className="headline2 marginBottom-6">Add to Calendar</h1>
      <ul>
        <li><a className="paddingTop-3 button button--secondary" href={google(eventData)} target="_blank" rel="noopener noreferrer">Google</a></li>
        <li><a className="paddingTop-3 button button--secondary" href={outlook(eventData)} target="_blank" rel="noopener noreferrer">Outlook</a></li>
        <li><a className="paddingTop-3 button button--secondary" href={office365(eventData)} target="_blank" rel="noopener noreferrer">Office365</a></li>
        <li><a className="paddingTop-3 button button--secondary" href={yahoo(eventData)} target="_blank" rel="noopener noreferrer">Yahoo</a></li>
      </ul>
    </div>
  )
}
