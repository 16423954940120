import React, { useState, useEffect } from "react";

export default function Collapse({
  className,
  buttonContents,
  buttonClasses,
  children,
  isOpen,
  hideButtonWhenOpen,
  render,
}) {
  const [contentIsShown, toggleContent] = useState(isOpen);

  useEffect(() => {
    toggleContent(isOpen);
  }, [isOpen]);

  return (
    <div className={`${className}`}>
      {(!contentIsShown || !hideButtonWhenOpen) && (
        <button
          type="button"
          className={`button--clean ${buttonClasses}`}
          onClick={() => toggleContent(!contentIsShown)}
        >
          {buttonContents(contentIsShown)}
        </button>
      )}
      {contentIsShown && (render ? render(toggleContent) : children)}
    </div>
  );
}
