import firebase from "gatsby-plugin-firebase";
import React, { useState, useMemo } from "react";
import { getErrorMessage } from "../utils/helpers";
import {getUsersByEvent, getEventByUID} from "../utils/firebase-utils";
import Select from "./Select";
import Button from "./Button";

const SendEventReminder = ({onSuccess, events}) => {
  
  const items = useMemo(() => events.map(event =>  ({"value": event.frontmatter.uid, "label": event.frontmatter.title})), events);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState(items[0].value);
  const [hasError, setHasError] = useState(false);
  
  const handleError = (message) => {
    setIsSubmitting(false);
    setHasError(getErrorMessage(message));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log('selected event', selected);
    const event = await getEventByUID(selected);
    console.log("event: ", event.title);
    const users = await getUsersByEvent(selected);
    const {data} = await firebase.functions().httpsCallable("sendEventReminderEmail")({users, event});
    const errors = data.some(res => res.status === 'rejected');
    if(errors) { handleError('some failed, check console') }
    data.forEach(res => console.log(res.value));
    setIsSubmitting(false);
    onSuccess('success');
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <h1 className="headline2 marginBottom-10">Send Event Reminder</h1>
      <div className="marginBottom-6">
        <Select 
          className="width--100" 
          items={items}
          selected={selected}
          onChange={(e) => setSelected(e.target.value)} 
        />
      </div>

      {hasError && <p className="c-red">{hasError}</p>}
      <div className="flex flex-justify--end">
        <Button
          disabled={isSubmitting}
          className={`button ${isSubmitting ? "button--loading" : ""}`}
          type="submit"
          onClick={handleSubmit}
        >
          {isSubmitting ? <span>Sending...</span> : "Send Reminder Emails"}
        </Button>
      </div>
    </form>
  );
};

export default SendEventReminder;
